import { Box } from "@chakra-ui/react";
import styles from "./index.module.scss";

export default function CButton(props: {
  name: string;
  leftIcon?: JSX.Element;
  onClick?: () => void;
}) {
  return (
    <Box className={styles.container} onClick={props.onClick}>
      {props.leftIcon && props.leftIcon}

      {props.name}
    </Box>
  );
}
