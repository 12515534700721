import { Box, Flex, Image } from "@chakra-ui/react";
import styles from "./index.module.scss";
import CButton from "../../components/CButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import dcIcon from "../../assets/images/social/dc.png";
import xIcon from "../../assets/images/social/x.png";
import meIcon from "../../assets/images/social/me.png";

import nft1Icon from "../../assets/images/nft/1.png";
import nft2Icon from "../../assets/images/nft/2.png";
import nft3Icon from "../../assets/images/nft/3.png";
import nft4Icon from "../../assets/images/nft/4.png";
import nft5Icon from "../../assets/images/nft/5.png";
import nft6Icon from "../../assets/images/nft/6.png";

const nftIcons = [nft1Icon, nft2Icon, nft3Icon, nft4Icon, nft5Icon, nft6Icon];

export default function Home() {
  const handleButtonClick = (link: string) => {
    const newWindow = window.open(link, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.mainLogoContainer}>
        <Box className={styles.mainLogo} />
      </Box>
      <Flex className={styles.sloganContainer}>
        <Flex className={styles.slogan}>
          A collection deeply rooted in Solana to disrupt the tap-to-earn space
        </Flex>
      </Flex>

      <Flex className={styles.btns}>
        <CButton
          name="Discord"
          leftIcon={<Image src={dcIcon} />}
          onClick={() => handleButtonClick("https://discord.gg/MCJkYnpnjv")}
        />
        <CButton
          name="Twitter"
          leftIcon={<Image src={xIcon} />}
          onClick={() => handleButtonClick("https://x.com/CryptoToothless")}
        />
        <CButton name="Magic Eden" leftIcon={<Image src={meIcon} />} />
      </Flex>

      <Flex className={styles.footer}>
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          slidesPerView={5}
          speed={3500}
          autoplay={{
            delay: 500,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={false}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {nftIcons.map((icon, index) => {
            return (
              <SwiperSlide key={index} className={styles.swiperSlide}>
                <Image src={icon} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Flex>
    </Box>
  );
}
